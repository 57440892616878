<template>
  <div>
    <v-row align="center" justify="center">
      <v-col md="10" sm="11">
        <div class="d-grid">
          <ClientData :client="client" class="client-data" />
          <ClientPurchaseData
            :lastPurchaseDate="lastPurchase"
            :amountShopping="amountShopping"
            :client="client"
            class="client-purchase-data"
          />
          <!-- <ClientSaleOptions :client="client" class="client-sale-options" /> -->

          <ClientTransactionsHistoric
            :historic="finishedSalesFiltered"
            class="client-transactions-historic"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientData from './ClientData';
import ClientPurchaseData from './ClientPurchaseData';
import ClientTransactionsHistoric from './ClientTransactionsHistoric';
import ClientSaleOptions from './ClientSaleOptions';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('orders');

import moment from 'moment';

moment.locale('pt-br');

export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['finishedSalesUnMask', 'finishedSales']),
    finishedSalesUnMaskFiltered() {
      return this.finishedSalesUnMask.filter((sale) => {
        return sale.clientId == this.client.id;
      });
    },
    finishedSalesFiltered() {
      return this.finishedSales.filter((sale) => {
        return sale.clientId == this.client.id;
      });
    },
    amountShopping() {
      return this.finishedSalesUnMaskFiltered.reduce((accum, sale) => {
        return +accum + +sale.amount;
      }, 0);
    },
    lastPurchase() {
      const sortedSales = this.finishedSalesUnMaskFiltered.sort((a, b) =>
        a.id < b.id ? 1 : -1
      );

      if (sortedSales.length > 0) {
        return moment(sortedSales[0].createdAt).format('DD/MM/YYYY');
      } else {
        return '-';
      }
    },
  },
  components: {
    ClientData,
    ClientPurchaseData,
    ClientTransactionsHistoric,
    ClientSaleOptions,
  },
};
</script>

<style scoped>
.d-grid {
  display: grid;
  grid-template-areas:
    'data purchase-data'
    'data purchase-data'
    'data purchase-data'
    'transactions-historic transactions-historic';
  grid-template-columns: 2fr 1.5fr;
  grid-gap: 25px;
}

.client-data {
  grid-area: data;
}
.client-purchase-data {
  grid-area: purchase-data;
}
.client-sale-options {
  grid-area: sale-options;
}
.client-transactions-historic {
  grid-area: transactions-historic;
}
</style>
