<template>
  <div>
    <v-row no-gutters justify="center">
      <v-col cols="12" sm="12" md="10" lg="9">
        <v-row no-gutters>
          <v-col cols="12" class="text-center mb-6">
            <span class="title" v-if="!(+client.id > 0)"
              >Vamos cadastrar uma pessoa!</span
            >
            <span class="title" v-else>Editar pessoa</span>
          </v-col>
        </v-row>
        <v-row no-margin>
          <v-col cols="8">
            <InputTextField label="Nome" v-model="client.name" />
          </v-col>
          <v-col cols="4">
            <InputTextField
              label="CPF"
              v-model="client.cpfCnpj"
              v-mask="'###.###.###-##'"
            />
          </v-col>
        </v-row>
        <v-row no-margin>
          <v-col cols="8">
            <InputTextField label="Email" v-model="client.email" />
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Telefone"
              hide-details
              v-model="client.phone"
              v-mask="phoneMask"
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-show="registerType === 'Completo'">
            <v-row no-margin>
              <v-col cols="8" class="d-flex justify-space-between align-center">
                <span>Sexo</span>
                <!-- <InputSwitch
                  :labels="sexList"
                  type="separate"
                  :default="sexList[0].name"
                  @change="(e) => (client.sex = e.value)"
                /> -->
                <span></span>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="menuBirthday"
                  v-model="menuDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="la-calendar"
                      v-model="birthdayBr"
                      label="Nascimento"
                      @keyup="onChangeBirthday"
                      v-on="on"
                      v-bind="attrs"
                      autocomplete="nope"
                      v-mask="'##/##/####'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    locale="pt-BR"
                    no-title
                    :active-picker.sync="activePicker"
                    v-model="client.birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1910-01-01"
                    @input="onChangeDatePicker"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-margin class="mt-6">
              <v-col cols="2">
                <v-text-field
                  hide-details
                  :loading="isLoadingCepData"
                  :disabled="isLoadingCepData"
                  label="CEP"
                  v-model="client.zipCode"
                  @keyup="findAddressByCep"
                />
              </v-col>
              <v-col cols="6">
                <InputTextField label="Rua" v-model="client.street" />
              </v-col>
              <v-col cols="2">
                <InputTextField label="Número" v-model="client.number" />
              </v-col>
              <v-col cols="2">
                <InputTextField
                  label="Complemento"
                  v-model="client.complement"
                />
              </v-col>
            </v-row>
            <v-row no-margin>
              <v-col cols="2">
                <InputTextField label="Bairro" v-model="client.neighborhood" />
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="cities"
                  :loading="isLoadingCities"
                  v-model="client.cityId"
                  item-text="nome"
                  autocomplete="nope"
                  no-data-text="Selecione um estado para ver as cidades"
                  item-value="id"
                  label="Cidade"
                  @change="
                    () => {
                      const city = getCity(client.cityId);
                      client.city = city ? city.nome : '';
                    }
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="client.state"
                  :items="states"
                  :loading="isLoadingStates"
                  item-text="nome"
                  autocomplete="nope"
                  item-value="id"
                  label="Estado"
                  @change="
                    () => {
                      loadCities(client.state);
                    }
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-row no-margin justify="center">
          <v-col cols="6" class="text-center mt-3">
            <v-btn
              block
              color="primary"
              depressed
              @click="onSubmit"
              :loading="isLoading"
              :disabled="isLoading"
              >Pronto!</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-margin justify="center" v-if="!client.id">
          <v-col cols="6" class="text-center mt-3">
            <v-btn text small @click="$emit('close', $event)"
              >Mudar para cadastro de empresa
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CLIENT_TYPE } from './../utils/names';
import CLIENT_MASKS_MIXIN from './../utils/mixins/masks';

import IBGE_CITIES_AND_STATES from '@/utils/mixins/ibge-cities-and-states';

import IBGEService from '@/modules/configurations/services/ibge-service';

import moment from 'moment';

export default {
  mixins: [CLIENT_MASKS_MIXIN, IBGE_CITIES_AND_STATES],
  props: {
    registerType: {
      type: String,
      default: 'Completo',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    clientData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    phoneMask() {
      return this.getPhoneMask(this.client.phone);
    },
  },
  async mounted() {
    this.loadCities();
    this.loadStates();
  },
  data() {
    return {
      birthdayBr:
        this.clientData &&
        this.clientData.birthday &&
        this.clientData.birthday.length == 10
          ? moment(this.clientData.birthday).format('DD/MM/YYYY')
          : '',
      activePicker: null,
      menuDatePicker: false,
      isLoadingCepData: false,
      sexList: [{ name: 'Masculino' }, { name: 'Feminino' }, { name: 'Outro' }],
      client: {
        type: CLIENT_TYPE.PERSON,
        phone: '',
        ehContribuinteICMS: false,
        isFinalCustomer: true,
        ...this.clientData,
      },
    };
  },
  watch: {
    client(newValue, oldValue) {
      let birthdayBr = '';
      if (newValue.birthday.length == 10) {
        birthdayBr = moment(newValue.birthday).format('DD/MM/YYYY');
      }

      if (this.birthdayBr != birthdayBr) this.birthdayBr = birthdayBr;
    },
    menuDatePicker(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    clientData(val) {
      this.$nextTick(() => {
        this.client = {
          ...val,
        };
      });
    },
  },
  methods: {
    onChangeDatePicker() {
      const birthdayBr = moment(this.client.birthday).format('DD/MM/YYYY');
      this.birthdayBr = birthdayBr;

      this.menuDatePicker = false;
    },
    onChangeBirthday() {
      // alert(value);

      const birthday = this.dateBrToEn(this.birthdayBr);

      if (birthday.length == 10) {
        this.$refs.menuBirthday.save(birthday);
        this.client.birthday = birthday;
      }else{
        this.client.birthday = null;
      }
    },
    async findAddressByCep() {
      try {
        const cepResponse = await this.findAddressByZipCode(
          this.client.zipCode
        );

        if (cepResponse) {
          this.isLoadingCepData = true;

          const state = this.states.find(
            ({ sigla }) => sigla == cepResponse.state
          );

          if (state) cepResponse.state = state.id;

          // //console.log(this.cities);
          await this.loadCities(state.id);

          const city = this.cities.find(({ nome }) => nome == cepResponse.city);
          if (city) cepResponse.cityId = city.id;

          this.client = {
            ...this.client,
            ...cepResponse,
          };
        }
      } catch (err) {
        //console.log(err);
      }

      this.isLoadingCepData = false;
    },
    onSubmit() {
      this.$emit('onSubmit', {
        client: this.client,
        clearForm: () => {
          this.client = {
            type: CLIENT_TYPE.PERSON,
            ehContribuinteICMS: false,
            isFinalCustomer: true,
          };
        },
      });
    },
  },
};
</script>
