import IBGEService from '@/modules/configurations/services/ibge-service';

export default {
  data() {
    return {
      cities: [],
      states: [],
      isLoadingCities: false,
      isLoadingStates: false
    };
  },
  methods: {
    getState(stateId) {
      return this.states.find(c => c.id == stateId);
    },
    getCity(cityId) {
      return this.cities.find(c => c.id == cityId);
    },
    async loadCities(stateId = null) {
      this.isLoadingCities = true;

      try {
        let response = null;
        if (stateId) {
          if (localStorage.getItem('citiesOfState' + stateId) == null){
            response = await IBGEService.getCitiesByState(stateId);

            localStorage.setItem('citiesOfState' + stateId, JSON.stringify(response));
          }else
            response = JSON.parse(
              localStorage.getItem('citiesOfState' + stateId)
            );
        } else {
          response = { data: []};
        }

        this.cities = response.data;
      } catch (err) {
        //console.log(err);
      }

      this.isLoadingCities = false;
    },
    async loadStates() {
      this.isLoadingStates = true;

      try {
        let list = null;

        if (localStorage.getItem('states') == null) {
          const response = await IBGEService.getStates();
          list = response.data;

          localStorage.setItem('states', JSON.stringify(list));
        } else {
          list = JSON.parse(localStorage.getItem('states'));
        }

        this.states = list;
      } catch (err) {
        //console.log(err);
      }

      this.isLoadingStates = false;
    }
  }
};
