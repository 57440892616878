<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" :on="on"></slot>
      </template>
      <v-card>
        <v-card-title>
          <v-btn icon @click="$emit('close', $event)">
            <v-icon>la-angle-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <InputSwitch
            :labels="labelsSwitch"
            v-if="!(+client.id > 0)"
            :value="registerType"
            @change="registerType = $event.value"
            default="Completo"
          /> -->
        </v-card-title>
        <v-card-text>
          <v-slide-x-transition>
            <PersonForm
              :registerType="registerType"
              v-if="dialog"
              v-show="clientType === 1 || clientType === 'person'"
              :clientData="client"
              :isLoading="isLoading"
              @close="clientType = 2"
              @onSubmit="onSubmitClientForm"
            />
          </v-slide-x-transition>
          <v-slide-x-transition>
            <CompanyForm
              :registerType="registerType"
              v-if="dialog"
              v-show="clientType === 2 || clientType === 'company'"
              :clientData="client"
              :isLoading="isLoading"
              @close="clientType = 1"
              @onSubmit="onSubmitClientForm"
            />
          </v-slide-x-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar app v-model="showNotify" :color="colorMessage">
      {{ message }}
      <v-btn
        :color="colorMessage == 'primary' ? 'white' : 'red'"
        icon
        @click="showNotify = false"
      >
        <v-icon>la la-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CompanyForm from './CompanyForm';
import PersonForm from './PersonForm';

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('clients');

export default {
  components: {
    CompanyForm,
    PersonForm,
  },
  data() {
    return {
      labelsSwitch: [{ name: 'Simples' }, { name: 'Completo' }],
      isLoading: false,
      registerType: 'Completo',
      showNotify: false,
      colorMessage: '',
      message: '',
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    clientType: {
      default: 1,
    },
    client: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  // watch: {
  //   isLoading(newValue){
  //     alert(newValue);
  //   }
  // },
  methods: {
    ...mapActions(['createClient', 'editClient']),
    async onSubmitClientForm({ client, clearForm }) {
      this.isLoading = true;

      //console.log(client);
      try {
        if (client.id) {
          await this.editClient({ client });
        } else {
          await this.createClient({ client });
        }

        this.isLoading = false;
        this.showMessage('Salvo com sucesso!', 'primary');

        clearForm();

        this.$emit('close');
      } catch (err) {
        try {
          if(err.response.data.message) this.showMessage(err.response.data.message);
          else this.showMessage(err.response.data.errors[0].message);
        } catch (err) {
          this.showMessage('Erro ao cadastrar');
        }
      }

      this.isLoading = false;
    },
    showMessage(message, color = '') {
      this.message = message;
      this.colorMessage = color;
      this.showNotify = true;
    },
  },
  created() {
    if (this.$route.params.type) {
      this.dialog = true;
    }
  },
};
</script>

<style scoped>
.v-dialog {
  overflow-y: hidden;
}
</style>
