<template>
  <div>
    <v-card outlined class="mx-auto fill-height" width="100%">
      <v-card-title class="pl-7">
        <LineBorder left top />
        Dados do cliente
        <v-spacer />
        <ButtonSecondary @click="openEditModal">Editar</ButtonSecondary>
      </v-card-title>
      <v-card-text>
        <v-list dense subheader>
          <v-list-item>
            <v-list-item-icon>
              <v-icon v-bind="propsIcons">la-user</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <p>{{ client.cpfCnpj }}</p>
              <p>{{ client.birthDay }}</p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <PhoneIcon v-bind="propsIcons"></PhoneIcon>
            </v-list-item-icon>
            <v-list-item-content>
              <p v-if="client.phone">
                {{ client.phone }}
              </p>
              <p v-if="client.email">
                {{ client.email }}
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <LocationIcon v-bind="propsIcons"></LocationIcon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-col cols="8" class="flex-grow-1 flex-shrink-0">
                  <p>{{ completeAddress }}</p>
                </v-col>

                <v-col cols="4" class="flex-grow-0 flex-shrink-0 text-right">
                  <ButtonSecondary right @click="showMaps = true"
                    >Ver no mapa</ButtonSecondary
                  >
                </v-col>
              </v-row>
              <MapsView
                :showModal="showMaps"
                @close="showMaps = false"
                :location="completeAddress"
              ></MapsView>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <TextIcon v-bind="propsIcons"></TextIcon>
            </v-list-item-icon>
            <v-list-item-content>
              <div>
                <p>{{ client.note }}</p>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <ClientForm
      :dialog="showEditModal"
      :clientType="+clientWithoutMasks.type"
      :client="clientWithoutMasks"
      @close="showEditModal = false"
    />
  </div>
</template>

<script>
import { mapAddress } from '@/utils/helpers/address';

import ClientForm from '../forms/ClientForm';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('clients');

export default {
  components: {
    ClientForm,
  },
  props: {
    client: Object,
  },
  methods: {
    openEditModal() {
      this.showEditModal = true;
    },
  },
  // watch: {
  //   clientWithoutMasks(newValue){
  //     alert(newValue.cpfCnpj);
  //   }
  // },
  computed: {
    completeAddress() {
      return mapAddress(this.client);
    },
    ...mapGetters(['getClientWithoutMasks']),
    clientWithoutMasks() {
      const clientClean = this.getClientWithoutMasks(this.client.id);

      return { ...clientClean };
    },
  },
  data() {
    return {
      showMaps: false,
      showEditModal: false,
      propsIcons: {
        color: 'primary',
        width: 20,
        class: 'icon-primary',
      },
    };
  },
};
</script>

<style scoped>
p {
  font-size: 12px;
  line-height: 1.3;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}
.v-list-item {
  padding: 0 12px;
}
</style>
