<template>
  <div>
    <v-row no-gutters justify="center">
      <v-col cols="12" sm="12" md="10" lg="9">
        <v-row no-gutters>
          <v-col cols="12" class="text-center mb-6">
            <span class="title" v-if="!client.id"
              >Vamos cadastrar uma empresa!</span
            >
            <span class="title" v-else>Editar empresa</span>
          </v-col>
        </v-row>
        <v-row no-margin>
          <v-col cols="8">
            <InputTextField label="Razão Social" v-model="client.name" />
          </v-col>
          <v-col cols="4">
            <v-text-field
              hide-details
              label="CNPJ"
              v-model="client.cpfCnpj"
              v-mask="'##.###.###/####-##'"
            />
          </v-col>
        </v-row>
        <v-row no-margin>
          <v-col cols="8">
            <InputTextField label="Email" v-model="client.email" />
          </v-col>
          <v-col cols="4">
            <v-text-field
              hide-details
              label="Telefone"
              v-model="client.phone"
              v-mask="phoneMask"
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-show="registerType === 'Completo'">
            <v-row no-margin>
              <v-col cols="4">
                <InputTextField
                  label="Inscrição Estadual"
                  v-model="client.stateRegistration"
                />
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="client.ehContribuinteICMS"
                  label="É contribuinte do ICMS"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="client.isFinalCustomer"
                  label="É consumidor final"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-margin class="mt-6">
              <v-col cols="2">
                <v-text-field
                  hide-details
                  :loading="isLoadingCepData"
                  :disabled="isLoadingCepData"
                  label="CEP"
                  v-model="client.zipCode"
                  @keyup="findAddressByCep"
                />
              </v-col>
              <v-col cols="6">
                <InputTextField label="Rua" v-model="client.street" />
              </v-col>
              <v-col cols="2">
                <InputTextField label="Número" v-model="client.number" />
              </v-col>
              <v-col cols="2">
                <InputTextField
                  label="Complemento"
                  v-model="client.complement"
                />
              </v-col>
            </v-row>
            <v-row no-margin>
              <v-col cols="2">
                <InputTextField label="Bairro" v-model="client.neighborhood" />
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="cities"
                  :loading="isLoadingCities"
                  v-model="client.cityId"
                  @change="
                    () => {
                      const city = getCity(client.cityId);
                      client.city = city ? city.nome : '';
                    }
                  "
                  item-text="nome"
                  no-data-text="Selecione um estado para ver as cidades"
                  autocomplete="nope"
                  item-value="id"
                  label="Cidade"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  :items="states"
                  :loading="isLoadingStates"
                  item-text="nome"
                  autocomplete="nope"
                  item-value="id"
                  label="Estado"
                  @change="
                    () => {
                      loadCities(client.state);
                    }
                  "
                  v-model="client.state"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-row no-margin justify="center">
          <v-col cols="6" class="text-center mt-3">
            <v-btn
              block
              color="primary"
              depressed
              @click="onSubmit"
              :loading="isLoading"
              :disabled="isLoading"
              >Pronto!</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-margin justify="center" v-if="!client.id">
          <v-col cols="6" class="text-center mt-3">
            <v-btn text small @click="$emit('close', $event)"
              >Mudar para cadastro de pessoa
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CLIENT_TYPE } from './../utils/names';
import CLIENT_MASKS_MIXIN from './../utils/mixins/masks';

import IBGE_CITIES_AND_STATES from '@/utils/mixins/ibge-cities-and-states';

export default {
  mixins: [CLIENT_MASKS_MIXIN, IBGE_CITIES_AND_STATES],
  props: {
    registerType: {
      type: String,
      default: 'Completo',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    clientData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  async created() {
    this.loadCities();
    this.loadStates();
  },
  data() {
    return {
      isLoadingCepData: false,
      client: {
        type: CLIENT_TYPE.COMPANY,
        phone: '',
        ehContribuinteICMS: true,
        isFinalCustomer: false,
        ...this.clientData,
      },
    };
  },
  computed: {
    phoneMask() {
      return this.getPhoneMask(this.client.phone);
    },
  },
  watch: {
    clientData(val) {
      this.client = {
        ...val,
      };
    },
    // client(newValue) {
    //   alert(newValue.cpfCnpj);
    // },
  },
  methods: {
    async findAddressByCep() {
      try {
        const cepResponse = await this.findAddressByZipCode(
          this.client.zipCode
        );

        if (cepResponse) {
          
          this.isLoadingCepData = true;

          const state = this.states.find(
            ({ sigla }) => sigla == cepResponse.state
          );

          if (state) cepResponse.state = state.id;

          // //console.log(this.cities);
          await this.loadCities(state.id);

          const city = this.cities.find(({ nome }) => nome == cepResponse.city);
          if (city) cepResponse.cityId = city.id;

          this.client = {
            ...this.client,
            ...cepResponse,
          };
        }
      } catch (err) {
        //console.log(err);
      }

      this.isLoadingCepData = false;
    },
    onSubmit() {
      this.$emit('onSubmit', {
        client: this.client,
        clearForm: () => {
          this.client = {
            type: CLIENT_TYPE.COMPANY,
            ehContribuinteICMS: true,
            isFinalCustomer: false,
          };
        },
      });
    },
  },
};
</script>
