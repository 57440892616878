<template>
  <div class="mb-10">
    <v-card outlined class="mx-auto fill-height">
      <v-card-title class="pl-7">
        <LineBorder left top color="pink" />
        Histórico de transações
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          hide-default-footer
          class="table-hover-pointer striped"
          no-data-text="Sem dados"
          no-results-text="Nenhum resultado encontrado"
          :show-select="false"
          v-bind="tableTransactionsHistoricConfig"
          :items="historic"
        >
          <template v-slot:item.id="{ item }">
            {{ item.id }}
            <small v-if="item.isTest">(teste)</small>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ toDateBr(item.createdAt) }}
          </template>
          <template v-slot:item.products="{ item }">
            {{ getProductsName(item.orderProducts) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import tableTransactionsHistoricConfig from '../utils/mixins/table/transactions-historic-config';

import moment from 'moment';

moment.locale('pt-br');

export default {
  mixins: [tableTransactionsHistoricConfig],
  props: {
    historic: Array,
  },
  methods: {
    getProductsName(products) {
      return products.map((p) => p.name).join(', ');
    },
    toDateBr(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
};
</script>
