<template>
  <div class="content-scroll">
    <ClientPageHeader :title="client.name" />
    <ClientDetails :client="client" />
    <AppFooter>
      <v-row>
        <v-col cols="6">
          <v-btn
            text
            @click="$router.back()"
          >
            <v-icon>la-angle-left</v-icon> Voltar para {{CLIENT_NAMES.PLURAL}}
          </v-btn>
        </v-col>
        <!-- <v-col
          cols="6"
          class="text-right"
        >
          <ButtonPrimary class="inline">Pronto!</ButtonPrimary>
        </v-col> -->
      </v-row>
    </AppFooter>
  </div>
</template>

<script>

import ClientDetails from './../components/ClientDetails';
import ClientPageHeader from './../components/ClientPageHeader';

import { CLIENT_NAMES } from './../utils/names';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('clients');

export default {
  name: 'ClientPageList',
  components: {
    ClientDetails,
    ClientPageHeader
  },
  computed: {
    ...mapGetters(['getClient', 'clients'])
  },
  methods: {
    setClient () {
      if (Number.isInteger(+this.$route.params.id)) {
        this.client = this.getClient(+this.$route.params.id);
      }
    }
  },
  mounted () {
    this.setClient();
  },
  watch: {
    $route (to, from) {
      this.setClient();
    },
    clients(newVal){
      this.setClient();
    }
  },
  data () {
    return {
      client: {},
      CLIENT_NAMES
    };
  }
};
</script>
