export default {
  methods: {
    getPhoneMask(phone) {
      if(!phone) return '';
      var desired = phone.replace(/[^\w\s]/gi, '')
      desired = desired.replace(/\s/g, '');
      desired = desired.split("")
      if (phone.length > 2 && phone[2] != '9') {
        return '(##) ####-####';
      } else {
        return '(##) #####-####';
      }
    }
  }
};

