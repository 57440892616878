
export default {
  data () {
    return {
      tableTransactionsHistoricConfig: {
        headers: [
          { text: 'Nº', value: 'id', width: '15%' },
          { text: 'Natureza', value: "natureza", width: '15%' },
          { text: 'Produtos', value: 'products', width: '45%' },
          { text: 'Data', value: 'createdAt', width: '10%' },
          { text: 'Valor total', value: 'amount', width: '15%' }
        ]
      }
    };
  }
};
