import axios from 'axios';

const api = axios.create({
  baseURL: `https://servicodados.ibge.gov.br/api/v1/localidades`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const getCities = () => {
  return api.get('/municipios?orderBy=nome');
};

const getCitiesByState = (stateId) => {
    return api.get(`/estados/${stateId}/municipios?orderBy=nome`);
}

const getStates = () => {
    return api.get("/estados?orderBy=nome");
}

export default {
  getCities,
  getCitiesByState,
  getStates,
};
