<template>
  <AppPageHeader :title="titlePage">
    <slot>
    </slot>
  </AppPageHeader>
</template>
<script>

import { CLIENT_NAMES } from './../utils/names';

export default {
  name: 'ClientPageHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    titlePage(){
      return this.title.length > 0 ? this.title : CLIENT_NAMES.TITLE_PLURAL;
    }
  }
};
</script>
