<template>
  <v-card outlined>
    <v-card-title class="pl-7">
      <LineBorder
        left
        top
      />
      Opções de venda
    </v-card-title>
    <v-card-text class="pl-7">
      <v-row no-gutters>
        <v-col class="d-flex justify-center align-center">
          <label class="mr-1">Tabela de preços:</label>
        </v-col>
        <v-col cols="7">
          <InputSelect
            :items="priceTable"
            v-model="selected"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      priceTable: ['Padrão', 'Revendedor', 'Outro'],
      selected: 'Padrão'
    };
  }
};
</script>
