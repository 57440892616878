<template>
  <v-card outlined>
    <v-card-title class="pl-7 align-start">
      <LineBorder left top />
      <span class="mr-2">Compras</span>
      <v-spacer> </v-spacer>
      <div class="d-flex flex-column justify-space-between">
        <v-sheet
          class="pl-3 pr-7 py-3 d-flex flex-column justify-space-between sheet-purchases"
          relative
        >
          <LineBorder negative-left top color="success" />
          <small class="caption font-weight-light">Valor total</small>
          <span class="subtitle-1 font-weight-medium"
            >R$ {{ amountShopping }}</span
          >
        </v-sheet>
        <v-sheet
          class="pl-3 pr-7 py-3 mt-4 d-flex flex-column justify-space-between sheet-purchases"
          relative
        >
          <LineBorder negative-left top />
          <small class="caption font-weight-light">Última compra</small>
          <span class="subtitle-1 font-weight-medium">{{
            lastPurchaseDate
          }}</span>
        </v-sheet>
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    lastPurchaseDate: {
      type: String,
      default: '',
    },
    amountShopping: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      price_table: ['Padrão', 'Revendedor', 'Outro'],
      selected: 'Padrão',
    };
  },
};
</script>

<style>
.v-sheet.sheet-purchases {
  background: var(--v-backgroundSecondary-base);
}
</style>
